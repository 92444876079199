const tenant = 'unob2c.onmicrosoft.com';
const version = '1.2.1.0';
const loginURL = 'https://unob2c.b2clogin.com/';

const prodconfig = {
    apiUrl: 'https://api-appshub.coolsystemsselect.com/', //'https://devapi.carrierappshub.com/',
    uiUrl: 'https://prod.d137pydh88anh9.amplifyapp.com/',
    // uiUrl: http://localhost:4200/,
    // // userPoolId: "us-east-2_rjmskKvmB",
    // // identityPoolId: "us-east-2:d134dd1a-560b-46cf-a917-c9aae0684eea",
    clientId: "g93e8ork1147c6tktj8hlf779",
    // // domain: "carrierum.auth.us-east-2.amazoncognito.com",
    postLogOutUri: 'https://prod.d137pydh88anh9.amplifyapp.com',
    redirectAppUri: 'https://auth-appshub.coolsystemsselect.com',
    appType: 'CSS',
    authenticateOnly: true,
}

const qaconfig = {
    apiUrl: 'https://qaapi-appshub.coolsystemsselect.com/',
    uiUrl: 'https://qa-apps.coolsystemsselect.com/',
    // uiUrl: 'http://localhost:4200/',
    clientId: "4lvfhehheh6cm5rb1ge6plpoo3",
    // postLogOutUri: 'http://localhost:4200',
    postLogOutUri: 'https://qa-apps.coolsystemsselect.com',
    redirectAppUri: 'https://devauth-appshub.coolsystemsselect.com',
    // redirectAppUri: 'http://localhost:4201',
    appType: 'CSS',
    authenticateOnly: true,
}

const devconfig = {
    apiUrl: 'https://devapi-appshub.coolsystemsselect.com/',
    uiUrl: 'https://dev-apps.coolsystemsselect.com/',
    // uiUrl: 'http://localhost:4200/',
    // clientId: "4lvfhehheh6cm5rb1ge6plpoo3",
    clientId: "g93e8ork1147c6tktj8hlf779", // TODO:AJ update back to dev
    // postLogOutUri: 'http://localhost:4200',
    postLogOutUri: 'https://dev-apps.coolsystemsselect.com',
    redirectAppUri: 'https://devauth-appshub.coolsystemsselect.com',
    // redirectAppUri: 'http://localhost:4201',
    appType: 'CSS',
    authenticateOnly: true,
}


/////////////////////////////////////////////////////////////////////////////
//DEV
export const appConfig = {
    api: {
        development: 'https://devapi-appshub.coolsystemsselect.com',
        clientID: '7e9a741a-cbf6-4abc-bf6d-0cfade927b0b', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
        authority:
            loginURL + tenant + '/B2C_1_SUSI_POL', // Sign up and Sign In Policy
        forgotpasswordAuthority:
            loginURL + tenant + '/B2C_1_ResetPassword/', // Forget password policy name
        clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
        // postLogoutRedirectUri: 'https://www.coolsystemsselect.com',
        postLogoutRedirectUri: 'https://dev-apps.coolsystemsselect.com',
        // postLogoutRedirectUri: 'http://localhost:4200',
        encryption: {
            IV: "8080808080808080",
            Key: "8080808080808080"
        },
        version: version,
        tenant: tenant,
        authorityBase: loginURL,
        authorityURL: 'B2C_1_SUSI_POL',
        // accessKeyId: 'AKIA5S4POE5IGLBREBEB',
        // secretAccessKey: '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',
        // region: 'us-east-2',
        blobUrl: 'https://staticfilesappshubdev.coolsystemsselect.com/logo/',
        blobSAS: '?sp=r&st=2021-08-03T09:49:37Z&se=2023-08-01T17:49:37Z&spr=https&sv=2020-08-04&sr=c&sig=4J9QRdWQYwXfA%2BsuMeZcKwHipQN3VFPxwM6lasQPfQQ%3D',
        loginConfig: devconfig  // prodconfig //devconfig
    }
}

export default appConfig


/////////////////////////////////////////////////////////////////////////////
//QA


// export const appConfig = {
//     api: {
//         development: 'https://qaapi-appshub.coolsystemsselect.com',
//         clientID: '7e9a741a-cbf6-4abc-bf6d-0cfade927b0b', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
//         authority:
//             loginURL + tenant + '/B2C_1_SUSI_POL', // Sign up and Sign In Policy
//         forgotpasswordAuthority:
//             loginURL + tenant + '/B2C_1_ResetPassword/', // Forget password policy name
//         clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
//         // postLogoutRedirectUri: 'https://www.coolsystemsselect.com',
//         postLogoutRedirectUri: 'https://qa-apps.coolsystemsselect.com',
//         // postLogoutRedirectUri: 'http://localhost:4200',
//         encryption: {
//             IV: "8080808080808080",
//             Key: "8080808080808080"
//         },
//         version: version,
//         tenant: tenant,
//         authorityBase: loginURL,
//         authorityURL: 'B2C_1_SUSI_POL',
//         // accessKeyId: 'AKIA5S4POE5IGLBREBEB',
//         // secretAccessKey: '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',
//         // region: 'us-east-2',
//         blobUrl: 'https://staticfilesappshubqa.coolsystemsselect.com/logo/',
//         blobSAS: '?sp=r&st=2021-08-03T09:49:37Z&se=2023-08-01T17:49:37Z&spr=https&sv=2020-08-04&sr=c&sig=4J9QRdWQYwXfA%2BsuMeZcKwHipQN3VFPxwM6lasQPfQQ%3D',
//         loginConfig: qaconfig  // prodconfig //devconfig
//     }
// }

// export default appConfig


/////////////////////////////////////////////////////////////////////////
//PROD
// export const appConfig = {
//     api: {
//         development: 'https://api-appshub.coolsystemsselect.com',
//         clientID: '7e9a741a-cbf6-4abc-bf6d-0cfade927b0b', // This is for local env.. for deployment use this guy -> 63fe9b41-f48c-4098-bc6c-d691ad542355 -> B2C - Application
//         authority:
//             loginURL + tenant + '/B2C_1_SUSI_POL', // Sign up and Sign In Policy
//         forgotpasswordAuthority:
//             loginURL + tenant + '/B2C_1_ResetPassword/', // Forget password policy name
//         clientScope1: 'https://' + tenant + '/umdevapi/user_impersonation',
//         // postLogoutRedirectUri: 'https://www.coolsystemsselect.com',
//         postLogoutRedirectUri: 'https://prod.d137pydh88anh9.amplifyapp.com',
//         // postLogoutRedirectUri: 'http://localhost:4200',
//         encryption: {
//             IV: "8080808080808080",
//             Key: "8080808080808080"
//         },
//         version: version,
//         tenant: tenant,
//         authorityBase: loginURL,
//         authorityURL: 'B2C_1_SUSI_POL',
//         // accessKeyId: 'AKIA5S4POE5IGLBREBEB',
//         // secretAccessKey: '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',
//         // region: 'us-east-2',
//         blobUrl: 'https://staticfilesappshub.coolsystemsselect.com/logo/',
//         blobSAS: '?sp=r&st=2021-08-03T09:49:37Z&se=2023-08-01T17:49:37Z&spr=https&sv=2020-08-04&sr=c&sig=4J9QRdWQYwXfA%2BsuMeZcKwHipQN3VFPxwM6lasQPfQQ%3D',
//         loginConfig: prodconfig  // prodconfig //devconfig
//     }
// }

// export default appConfig